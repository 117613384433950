import { Router } from '@angular/router';
import { AuthService } from './../../services/auth/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable,  throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor  {

  constructor(
    private authService: AuthService,
    private router: Router,
    ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = request.clone({
      setHeaders: {
        'Authorization': `${this.authService.getToken()}`,
        'x-db-frontend' : 'cms_partner' 
      }
    });
    return next.handle(request).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if ([403,401].includes(err.status)) {
              // Invalidate user session and redirect to login/home
              localStorage.removeItem('access');
              this.router.navigate(['/login'], {queryParams:{msg:err.error.error}})
          }
          // return the error back to the caller
          return throwError(err);
        }
      }),
      finalize(() => {
        // any cleanup or final activities
      })
    );
  }
}

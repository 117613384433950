<div class="login">
    <div class="container h-100">
        <div class="row align-items-center h-100">
            <div class="col-md-5 mx-auto">
                <form [formGroup]="loginForm">
                    <ng-container *ngIf="error">
                        <div class="alert alert-danger">
                            {{'failLogin' | translate}}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="success">
                        <div class="alert alert-primary">
                            {{'successLogin' | translate}}
                        </div>
                    </ng-container>
                    <div style="display: flex; align-items: center; justify-content: center;"><img src="../../assets/images/logo_round.png" width="40%" /></div>
                    <div class="form-group">
                        <label for=""><b>{{'username' | translate}}</b></label>
                        <input type="text" formControlName="username" class="form-control">
                    </div>
                    <div class="form-group">
                        <label for=""><b>{{'password' | translate}}</b></label>
                        <input type="password" formControlName="password" class="form-control">
                    </div>
                    <button class="btn btn-primary disabled" [disabled]="!loginForm.valid || loading" (click)="login(loginForm.value)">{{(loading === true ? 'loading' : 'login') | translate}}</button>
                </form>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shell',
  template: `
        <router-outlet></router-outlet>
    `,
})
export class AppShellComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}

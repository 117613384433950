import {Injectable} from '@angular/core';
import {User} from '../../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() {
  }

  loggedIn() {
    // check if there is token
    return !!localStorage.getItem('access');
  }

  getToken(): string {
    // get token from session storage
    return localStorage.getItem('access');
  }

  tokenNotExpired(tokenVal: string): boolean {
    return !!tokenVal;
  }

  isAuthenticated(): boolean {
    // get the token
    const token = this.getToken();
    // return a boolean indicating whether or not the token is expired
    return this.tokenNotExpired(token);
  }

  setToken(token: string | undefined): void {
    if (token === undefined) {
      localStorage.removeItem('access');
      return;
    }

    localStorage.setItem('access', token);
  }

  getUser(): User | undefined {
    const payload = localStorage.getItem('user');
    if (payload === null || payload === undefined) {
      return undefined;
    }

    return JSON.parse(payload);
  }

  setUser(user: User | undefined): void {
    if (user === undefined) {
      localStorage.removeItem('user');
      return;
    }

    localStorage.setItem('user', JSON.stringify(user));
  }
}

import { AuthService } from './../../services/auth/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
// import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  constructor(private authService:AuthService, private route : Router){}
  canActivate(): boolean{
    if(this.authService.loggedIn()){
      // check if loogedIn
      return true;
    }else{
      // redirect if not loggedIn
      this.route.navigate(['/']);
      return false;
    }
  }
  
}

import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Login } from 'src/app/login/login.component';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private url = environment.baseUrl;
  constructor(private http:HttpClient) { }

  login(value:Login){
    return this.http.post(this.url + 'login', value).toPromise();
  }
}

import { Router } from '@angular/router';
import { LoginService } from './../services/login/login.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {AuthService} from '../services/auth/auth.service';
export interface Login{
  username: string;
  password:string
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  error:boolean=false;
  success:boolean=false;
  loading:boolean=false;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private router:Router,
    public translate: TranslateService,
    private authService: AuthService,
    ) { }

  ngOnInit(): void {
  }

  loginForm: FormGroup = this.fb.group({
    username: ['',[Validators.required]],
    password: ['',[Validators.required]]
  });

  login(value:Login){
    if(this.loginForm.valid){
      this.loading=true;
      this.loginService.login(value).then(
        (res:any)=>{
          this.success=true;
          this.error=false;
          this.authService.setToken(res.token);
          this.authService.setUser(res.user);
          this.router.navigate(['/pages'])
        },
        err=>{
          this.error=true;
          this.success=false;
        }
      ).catch(
        err=>{}
      ).finally(()=>this.loading=false)
    }
  }

}

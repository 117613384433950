import { AuthService } from './../../services/auth/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AnonymousGuardGuard implements CanActivate {
  constructor(private authService:AuthService, private route : Router){}
  canActivate(): boolean{
    if(this.authService.loggedIn()){
      this.route.navigate(['/pages']);
      return false;
    }else{
      return true;
    }
  }
  
}

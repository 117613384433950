import { AnonymousGuardGuard } from './guards/anonymous-guard/anonymous-guard.guard';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuardGuard } from './guards/auth-guard/auth-guard.guard';

const routes: Routes = [
  {
    path:'',
    component:AppComponent,
    children:[
      {
        path:'',
        redirectTo: 'login',
        pathMatch:'full',
      },
      {
        path:'login',
        component: LoginComponent,
        canActivate : [AnonymousGuardGuard]
      },
      {
        path:'pages',
        loadChildren: ()=> import('./pages/pages.module').then(m => m.PagesModule),
        canActivate : [AuthGuardGuard]
      },
    ]
  },
  {
    path:'**',
    redirectTo:'login',
    pathMatch:'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const RouteComponents=[
  LoginComponent
]
